import { render, staticRenderFns } from "./CoreBlockMedia.vue?vue&type=template&id=63c5ef48"
import script from "./CoreBlockMedia.vue?vue&type=script&lang=js"
export * from "./CoreBlockMedia.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default})
