
export default {
	name: "CoreBlockMedia",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	computed: {
		mediaUrl() {
			const mediaSrc = this.settings.src;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		isImage() {
			if (this.mediaUrl) {
				return (
					this.mediaUrl.toLowerCase().endsWith(".png") ||
					this.mediaUrl.toLowerCase().endsWith(".jpg") ||
					this.mediaUrl.toLowerCase().endsWith(".jpeg") ||
					this.mediaUrl.toLowerCase().endsWith(".webp") ||
					this.mediaUrl.toLowerCase().endsWith(".gif") ||
					this.mediaUrl.toLowerCase().endsWith(".svg") ||
					this.mediaUrl.toLowerCase().startsWith("data:image")
				);
			}
			return false;
		},
		isFile() {
			if (this.mediaUrl) {
				return (
					this.mediaUrl.toLowerCase().endsWith(".csv") ||
					this.mediaUrl.toLowerCase().endsWith(".pdf") ||
					this.mediaUrl.toLowerCase().endsWith(".html") ||
					this.mediaUrl.toLowerCase().endsWith(".txt")
				);
			}
			return false;
		},
		isVideo() {
			if (this.mediaUrl) {
				return (
					this.mediaUrl.match("youtube.com/embed") ||
					this.mediaUrl.match("player.vimeo") ||
					this.mediaUrl.match("players.brightcove") ||
					this.mediaUrl.toLowerCase().endsWith(".avi") ||
					this.mediaUrl.toLowerCase().endsWith(".mp4") ||
					this.mediaUrl.toLowerCase().endsWith(".wmv") ||
					this.mediaUrl.toLowerCase().endsWith(".mov") ||
					this.mediaUrl.toLowerCase().endsWith(".mkv") ||
					this.mediaUrl.toLowerCase().endsWith(".webm")
				);
			}
			return false;
		},
		mediaType() {
			if (this.isImage) {
				return "image";
			}
			if (this.isFile) {
				return "file";
			}
			if (this.isVideo) {
				return "video";
			}
			return null;
		},
	},
};
